body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C0C0C;
  color: #fff;
  overflow-x: hidden;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

#tsparticles {
  position: absolute;
  z-index: -10;
  top: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  font-family: 'PoppinsMedium';
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
button:disabled,
button[disabled]{
  opacity: 0.7;
}
.swiper-pagination-bullet {
  background: #363636 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #4C4AEF !important;
  opacity: 1 !important;
}

.Mui-checked {
  color: #4C4AEF !important;
}

.MuiLinearProgress-bar {
  background: rgb(230, 104, 31) !important;
  border-radius: 100px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  width: max-content !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 0 32px 0 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded:not(.MuiAlert-filledSuccess, .MuiAlert-filledError) {
  background-color: #191B1E !important;
  color: #D8D8D8 !important;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: #363636 !important;
}

.MuiTabs-flexContainer {
  justify-content: space-between !important;
}

.MuiTabs-flexContainer > button {
  color: #D8D8D8;
  font-family: 'PoppinsMedium';
  text-transform: none;
}

.MuiTabs-flexContainer > button.Mui-selected {
  color: #4C4AEF;
}

.MuiTabs-indicator {
  background-color: #4C4AEF !important;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('./assets/fonts/PoppinsMedium.eot');
  src: url('./assets/fonts/PoppinsMedium.eot') format('embedded-opentype'),
       url('./assets/fonts/PoppinsMedium.woff2') format('woff2'),
       url('./assets/fonts/PoppinsMedium.woff') format('woff'),
       url('./assets/fonts/PoppinsMedium.ttf') format('truetype'),
       url('./assets/fonts/PoppinsMedium.svg#PoppinsMedium') format('svg');
}


@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('./assets/fonts/PoppinsSemiBold.eot');
  src: url('./assets/fonts/PoppinsSemiBold.eot') format('embedded-opentype'),
       url('./assets/fonts/PoppinsSemiBold.woff2') format('woff2'),
       url('./assets/fonts/PoppinsSemiBold.woff') format('woff'),
       url('./assets/fonts/PoppinsSemiBold.ttf') format('truetype'),
       url('./assets/fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
}


@font-face {
  font-family: 'PoppinsRegular';
  src: url('./assets/fonts/PoppinsRegular.eot');
  src: url('./assets/fonts/PoppinsRegular.eot') format('embedded-opentype'),
       url('./assets/fonts/PoppinsRegular.woff2') format('woff2'),
       url('./assets/fonts/PoppinsRegular.woff') format('woff'),
       url('./assets/fonts/PoppinsRegular.ttf') format('truetype'),
       url('./assets/fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
}
