.menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.menuEl {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid rgb(39, 39, 39);
    > div {
        display: flex;
        gap: 10px;
    }
}