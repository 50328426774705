.Transactions {
    margin-bottom: 100px;
}

.transactionsEl {
    >p {
        margin-bottom: 8px;
    }
}

.body {
    padding: 12px;
    border-radius: 16px;
    background: rgb(25, 27, 30);
}

.header {
    display: flex;
    justify-content: space-between;

    >div:first-child {
        gap: 10px;
        display: flex;
        align-items: center;

        >div {
            display: flex;
            flex-direction: column;
            gap: 5px;

            >p:last-child {
                color: rgb(141, 141, 141);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    >div:last-child {
        display: flex;
        flex-direction: column;
        gap: 5px;

        >p:last-child {
            color: rgb(141, 141, 141);
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.copyAddress {
    display: flex;
    padding: 10px;
    align-items: center;
    border: 1px solid rgb(76, 74, 239);
    border-radius: 12px;
    justify-content: space-between;
    margin-top: 5px;
    >p:first-child {
        font-size: 10px;
        color: #D8D8D8;
    }
    > p:last-child {
        font-size: 14px;
        color: #D8D8D8;
        border-left: 2px solid #B4B4B4;
        padding-left: 10px;
    }
}

.btn {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: #9E1515;
    border-radius: 12px;
    border: none;
    color: #EDEDFD;
}

.capitalLetters::first-letter {
    text-transform: uppercase;
}

.comissions {
    margin: 10px 0;
    width: 100%;
    background-color: #FFD4BA;
    border-radius: 16px;
    padding: 16px;
    > div {
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        > p:first-child {
            font-weight: bold;
            color: #191B1E;
            font-size: 18px;
        }
        > p:last-child {
            font-size: 12px;
            color: #fff;
            background-color: #FF7422;
            padding: 4px 12px;
            border-radius: 100px;
        }
    }
    color: #191B1E;
    font-size: 12px;
}