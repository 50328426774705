.Action {
    margin: 0 20px;
    background: #27292CCC;
    padding: 16px 5px;
    border-radius: 16px;
    > div {
        display: flex;
        justify-content: space-between;
    }
    > div > button:not(.calculate) {
        display: flex;
        background: #4C4AEF;
        border-radius: 12px;
        padding: 8px;
        color: #EDEDFD;
        border: none;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-size: 16px;
    }
}

.calculate {
    background: #0B7B3A;
    padding: 12px 0;
    width: 100%;
    border: none;
    border-radius: 12px;
    margin-top: 16px;
    font-size: 16px;
    color: #EDEDFD;
    display: flex;
    justify-content: center;
    gap: 10px;
}
