.Calculator {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    background-color: #191B1E;
    border-radius: 12px;
    padding: 10px 14px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 8px;
    margin-top: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 16px;
    }
}

.btn {
    border: none;
    width: 100%;
    padding: 12px 0;
    border-radius: 12px;
    background: rgb(76, 74, 239);
    color: #EDEDFD;
}

.calculate {
    background: rgb(25, 27, 30);
    margin-top: 20px;
    text-align: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    >div>p:first-child {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 700;
        padding: 16px 0;
        border-bottom: 1px solid rgb(39, 39, 39);
    }

    >div>div {
        border-bottom: 1px solid rgb(39, 39, 39);
        display: flex;
        padding: 16px;
        justify-content: space-between;

        >p:first-child {
            font-size: 16px;
            font-weight: 700;
        }
    }
}