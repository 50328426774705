.vipContainer {
    background: #191B1E;
    margin: 0 20px;
    border-radius: 12px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.ok {
    border-radius: 12px;
    background: rgb(76, 74, 239);
    color: #fff;
    width: 100%;
    padding: 12px;
    border: none;
    font-size: 16px;
    margin-top: 25px;
    height: 48px;
}

.cancel {
    color: #4C4AEF;
    font-size: 16px;
    border: none;
    background-color: transparent;
    width: 100%;
    margin-top: 10px;
    font-weight: 550;
}

@media screen and (max-height: 650px) {
    .vipContainer {
        height: 100%;
        margin: 0;
    }

}