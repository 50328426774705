.Home {
    margin-bottom: 100px;
}

.header {
    background: transparent;
    margin: 4px 4px 0 4px;
    border-radius: 24px;
    padding: 30px 20px 110px 0;

    position: relative;
    > *:first-child {
        > * {
        border-radius: 24px;
        }
    }
}

.headerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}

.balance {
    display: flex;
    width: calc(100% - 30px);
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: #131517;
    background: rgba(19, 21, 23, 0.6);
    border-radius: 16px;
    padding: 30px 15px;
    margin: 15px;
    // top: 110px;
    left: 0;
}

.product {
    margin-top: 10px;
    gap: 10px;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 105px);
    padding: 0 20px;
}

.element {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    padding: 16px 16px 8px 16px;
    white-space: pre;
    font-size: 12px;
    color: #0C0C0C;
}

.news {
    padding: 0 20px;
    margin-top: 20px;
    > p {
        font-size: 20px;
    }
    > div {
        margin-top: 12px;
        background-color: #191B1E;
        border-radius: 16px;
        padding: 12px 16px;
    }
}

.news_header {
    font-size: 16px;
}

.news_body {
    color: #B4B4B4;
    font-family: 'PoppinsRegular';
    font-size: 12px;
    margin-top: 5px;
}

.buyVipBtn {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    width: calc(100% - 40px);
    border-radius: 12px;
    margin: 65px 20px 0 20px;
    background: linear-gradient(96.53deg, #FF9557 6.95%, #FDD0B6 107.8%);
    height: 48px;
    border: none;
}