.ErrorPageForPc {
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.header {
    width: 100%;
    height: 7vh;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.body {
    width: 100%;
    height: calc(100vh - 7vh);
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title {
    text-align: center;
    font-size: 30px;
    color: #000;
    font-weight: 900;
}
.description {
    color: #6c757d;
    font-weight: 700;
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width: 1330px) {
    .body > div:last-child {
        width: 45% !important;
    }
}

@media screen and (max-width: 890px) {
    .body > div:last-child {
        width: 55% !important;
    }
}

@media screen and (max-width: 700px) {
    .body > div:last-child {
        width: 70% !important;
    }
}

@media screen and (max-width: 500px) {
    .body > div:last-child {
        width: 90% !important;
    }

    .body > div:first-child {
        width: 100% !important;
    }
}