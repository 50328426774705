.Payouts {
    margin-bottom: 100px;
}

.payoutEl {
    border-radius: 24px;
    background: linear-gradient(180.00deg, rgb(189, 188, 255), rgb(210, 209, 255) 28.646%, rgb(226, 225, 255) 100%);
    padding: 24px 0;
    width: 100%;

    >img {
        width: 100%;
    }

    >p:first-child {
        color: rgb(25, 27, 30);
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
    }

    .transactionDetails {
        color: rgb(25, 27, 30);
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        margin: 10px 0;
    }

    >div:first-of-type {
        margin: 0 24px;
        display: flex;
        align-items: center;
        >p:last-child {
            color: #4C4AEF;
            white-space: normal;
            word-break: break-all;
            text-align: center;
            font-size: 14px;
        }

        >p:first-child {
            color: #363636;
            font-size: 14px;
        }
    }
    
    > div:last-of-type {
        margin: 10px 24px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
            display: flex;
            justify-content: space-between;
            color: #363636;
            font-size: 14px;
        }
    }
}