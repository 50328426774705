.loading_page {
    height: 100vh;
    width: 100%;
    background-color: #4C4AEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.loading_page__h1 {
    text-transform: uppercase;
    font-family: Arial Black;
    text-align: center;
    font-size: 28px;
    position: relative;
    top: -90px;

    -webkit-text-stroke: 0.5px black;
    color: white;
    text-shadow:
        0.5px 0.5px 0 #00000080,
        -0.5px -0.5px 0 #00000080,
        0.5px -0.5px 0 #00000080,
        -0.5px 0.5px 0 #00000080,
        0.5px 0.5px 0 #00000080;
}

.pulse2,
.pulse {
    width: 50px;
    z-index: -1;
    height: 50px;
    position: absolute;
    left: 51%;
    transform: translate(-50%, -50px);
}

.pulse2::before,
.pulse2::after,
.pulse::before,
.pulse::after {
    content: "";
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: #EDEDFD;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
}

.pulse::before {
    animation: pulse 3s ease-out infinite;
}

.pulse::after {
    animation: pulse 3s 1s ease-out infinite;
}


.pulse2::before {
    animation: pulse 3s ease-out infinite;
}

.pulse2::after {
    animation: pulse 3s 0.5s ease-out infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    100% {
        transform: scale(20);
        opacity: 0;
    }
}