.CryptoPUMP {
    margin-bottom: 100px;
}

.balance {
    margin: 5px 20px 20px;
    background: linear-gradient(180deg, #BDBCFF 0%, #D2D1FF 28.65%, #E2E1FF 100%);
    border-radius: 16px;
    padding: 15px;
    gap: 5px;
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.whatIs {
    margin-top: 30px;
    border-radius: 16px;
    padding: 12px 16px;
    margin: 30px 20px 0;
    background: #191B1E;
    > p:first-child {
        font-size: 16px;
    }
    > p:last-child {
        color: #B4B4B4;
        margin-top: 2px;
    }
}

.sheet {
    padding: 16px 16px 30px;

    >p:first-child {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    >p:last-child {
        color: #B4B4B4;
        font-size: 14px;
        font-weight: 400;
    }
}

.amount {
    margin-top: 16px;

    >p {
        color: #B4B4B4;
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.input {
    display: flex;
    background-color: #191B1E;
    border-radius: 12px;
    padding: 10px 14px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 8px;
    height: 50px;
    margin-top: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 16px;
    }
}

.sum {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    >button {
        border-radius: 12px;
        background: rgb(39, 39, 39);
        border: none;
        font-size: 14px;
        font-weight: 500;
        padding: 4px 8px 4px 8px;
        color: #fff;
    }
}

.btn {
    margin-top: 20px;
    border-radius: 12px;
    background-color: #4C4AEF;
    width: 100%;
    border: none;
    color: #EDEDFD;
    height: 50px;
}

.btn1 {
    border-radius: 12px;
    background: rgb(76, 74, 239);
    color: #fff;
    width: 100%;
    padding: 12px;
    border: none;
    font-size: 16px;
    margin-top: 15px;
}

.btn2 {
    color: #4C4AEF;
    font-size: 16px;
    border: none;
    background-color: transparent;
    width: 100%;
    margin-top: 10px;
}