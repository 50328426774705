.el {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    padding: 0 16px;
    border-radius: 12px;
}

.address {
    display: flex;
    border: 1px solid rgb(76, 74, 239);
    border-radius: 12px;
    background: rgb(25, 27, 30);
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px 10px 10px;
    gap: 5px;
    margin-top: 5px;

    >p {
        color: #B4B4B4;
        font-size: 10px;
    }
}

.btn1 {
    border-radius: 12px;
    background: rgb(76, 74, 239);
    color: #fff;
    width: 100%;
    padding: 12px;
    border: none;
    font-size: 16px;
}

.btn2 {
    color: #4C4AEF;
    font-size: 16px;
    border: none;
    background-color: transparent;
    width: 100%;
    margin-top: 10px;
}