.Header {
    color: #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(44, 44, 44);
}