.CryptoGameGuide {
    margin-bottom: 50px;
}

.startGame__body {
    display: flex;
    gap: 4px;
    > p {
        display: flex;
        background-color: #272727;
        width: 20%;
        color: #EDEDFD;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
}

.text {
    font-family: 'PoppinsRegular';
    font-size: 16px;
    color: #B4B4B4;
}

.btn {
    width: 100%;
    border: none;
    background-color: #4C4AEF;
    height: 50px;
    border-radius: 12px;
    font-size: 16px;
    color: #fff;
    margin-top: 50px;
}