.Notification {
    margin-bottom: 100px;
}

.notificationEL {
    display: flex;
    border-radius: 20px;
    background: #191B1E;
    align-items: start;
    padding: 18px;
    gap: 15px;

    >img {
        margin-top: 5px;
    }

    >div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        >p:first-child {
            font-size: 17px;
            font-weight: 500;
        }

        >p:nth-child(2) {
            color: rgb(216, 216, 216);
            font-size: 14px;
            font-weight: 400;
        }

        >p:last-child {
            color: rgb(180, 180, 180);
            font-size: 14px;
        }
    }
}