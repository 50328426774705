.skip {
    display: flex;
    justify-content: end;
    > button {
    margin-top: 20px;
    color: #FF7422;
    font-weight: 500;
    font-size: 16px;
    font-family: 'PoppinsMedium';
    text-align: end;
    background-color: transparent;
    border: none;
    }
}

.text {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    font-family: 'PoppinsMedium';
    color: #fff;
}

.pagination {
    text-align: center;
    color: #363636;
    margin-bottom: 10px;
}

.img2,
.img3,
.img {
    margin: 30px 0;
    width: 100%;
}

@media screen and (max-height: 700px) {
    .img {
        width: 90%;
    }
}
@media screen and (max-height: 650px) {
    .img {
        width: 80%;
    }
}
@media screen and (max-height: 600px) {
    .img {
        width: 70%;
    }
}
@media screen and (max-height: 580px) {
    .img {
        width: 60%;
    }
}



@media screen and (max-height: 750px) {
    .img2 {
        width: 85%;
    }
}
@media screen and (max-height: 700px) {
    .img2 {
        width: 75%;
    }
}
@media screen and (max-height: 600px) {
    .img2 {
        width: 65%;
    }
}
@media screen and (max-height: 560px) {
    .img2 {
        width: 55%;
    }
}



@media screen and (max-height: 750px) {
    .img3 {
        width: 80%;
    }
}

@media screen and (max-height: 700px) {
    .img3 {
        width: 70%;
    }
}

@media screen and (max-height: 650px) {
    .img3 {
        width: 55%;
    }
}

@media screen and (max-height: 600px) {
    .img3 {
        width: 45%;
    }
}

@media screen and (max-height: 570px) {
    .img3 {
        width: 40%;
    }
}