.Admin {
    margin-bottom: 100px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    gap: 50px;
}

.body_amount {
    min-height: 50px;
    width: 100%;
    background-color: #191B1E;
    border-radius: 12px;
    display: flex;
    padding: 10px 12px 10px 14px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: nowrap;
    margin-bottom: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 14px;
    }
    >textarea {
        width: 100%;
        background-color: #191B1E;
        resize: none;
        font-family: PoppinsMedium;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 14px;
    }
}

textarea::-webkit-resizer {
    display: none;
}
