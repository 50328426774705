.Transfer {
    margin-bottom: 100px;
}

.balance {
    display: flex;
    justify-content: space-between;

    >p:first-child {
        color: #363636;
        font-size: 14px;
    }

    >p:last-child {
        color: #0C0C0C;
        font-size: 20px;

        >span {
            font-size: 14px;
        }
    }
}

.transferWhere {
    margin-top: 5px;
    > p {
        color: #B4B4B4;
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.body_amount {
    height: 50px;
    background-color: #191B1E;
    border-radius: 12px;
    display: flex;
    padding: 10px 12px 10px 14px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: nowrap;
    margin-bottom: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 14px;
    }
}

.btn {
    border: none;
    color: #EDEDFD;
    width: 100%;
    padding: 16px 0;
    background-color: #4C4AEF;
    border-radius: 12px;
    margin-top: 15px;
    > p {
        font-size: 16px;
    }
}