.input {
    background-color: #191B1E;
    border-radius: 12px;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: nowrap;
    margin-bottom: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 16px;
    }
}

.btn {
    border: none;
    width: 100%;
    padding: 12px 0;
    border-radius: 12px;
    background: rgb(76, 74, 239);
    color: #EDEDFD;
    font-size: 16px;
    font-weight: 500;
    margin-top: 50px;
}

.convert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px;
}

.dash {
    border: 1px dashed rgb(54, 54, 54);
    flex-grow: 1;
}

.priceCoin {
    width: max-content;
    border-radius: 8px;
    background: rgb(25, 27, 30);
    padding: 8px 12px 8px 12px;
    display: flex;
    color: rgb(237, 237, 253);
    font-size: 14px;
    font-weight: 500;
}

.amount {
    >p {
        color: rgb(180, 180, 180);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
    }
}

.body_amount {
    height: 50px;
    background-color: #191B1E;
    border-radius: 12px;
    display: flex;
    padding: 10px 12px 10px 14px;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: nowrap;
    margin-bottom: 5px;

    >p:first-child {
        font-size: 14px;
        color: #363636;
    }

    >input {
        width: 100%;
        background-color: #191B1E;
        border: none;
        outline: none;
        color: #D8D8D8;
        font-size: 14px;
    }
}