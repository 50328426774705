.MiningBot {
    padding-bottom: 20px;
    background-image: url('../../assets/image/mining_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.mining_body {
    margin: 5px 20px 0;
    padding: 16px;
    background: linear-gradient(180.00deg, rgb(254, 198, 165) 0%, rgb(255, 231, 217) 93.794%);
    border-radius: 16px 16px 0px 0px;
}

.mining_info {
    margin: 0 20px;
    padding: 0 16px;
    background: rgb(246, 246, 246);
    border-radius: 0px 0px 16px 16px;

    >p {
        color: rgb(54, 54, 54);
        font-size: 14px;
        font-weight: 400;
        font-family: 'PoppinsRegular';
    }
}

.mining_list {
    margin: 10px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    >div>div {
        margin-top: 20px;
        padding: 16px;
        border-radius: 16px;
        background: rgb(25, 27, 30);
        display: flex;
        align-items: start;
        gap: 12px;

        >p {
            font-size: 17px;
            font-weight: 500;
        }

        >div {
            width: 100%;

            >div {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                >p:first-child {
                    color: rgb(180, 180, 180);
                    font-size: 11px;
                }

                >p:last-child {
                    color: rgb(216, 216, 216);
                    font-size: 12px;
                }
            }
        }
    }
}

.Action {
    margin: 5px 20px 20px;
    background: #27292CCC;
    padding: 5px;
    border-radius: 16px;

    >div {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    >div>button:not(.calculate) {
        display: flex;
        background: #4C4AEF;
        border-radius: 12px;
        padding: 8px;
        width: 50%;
        color: #EDEDFD;
        border: none;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-size: 16px;
    }
}

.coin {
    transition: transform 0.2s ease;
    transform: scale(1);
}

.coin.active {
    transform: scale(.9);
    /* Масштабирование при клике */
}

.addCoin {
    position: absolute;
    animation: fade-up 1s;
    opacity: 0;
}

@keyframes fade-up {
    0% {
        opacity: 1;
        display: block;
    }

    100% {
        top: -50px;
        display: none;
        opacity: 0;
    }
}