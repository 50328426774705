.Footer {
    position: fixed;
    border-top: 1px solid rgb(44, 44, 44);
    width: 100%;
    bottom: 0;
    display: grid;
    background-color: #0C0C0C;
    height: 75px;
    z-index: 99;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    align-items: center;
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
    }
}

.topUp {
    width: 50px;
    height: 50px;
    background-color: #4C4AEF;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}