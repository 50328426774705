.CryptoGame {
    margin-bottom: 100px;
    margin: 0 20px 20px 20px;
}

.balance {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gameStatus {
    background-color: #191B1E;
    padding: 4px 10px;
    border-radius: 16px;
    margin-top: 10px;
}

.gameStatus__body {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 8px;
    column-gap: 20px;

    >div {
        display: flex;
        justify-content: space-between;

        >p:first-child {
            color: #8D8D8D;
            font-size: 14px;
        }

        >p:last-child {
            color: #B7B6F8;
            font-size: 14px;
        }
    }
}

.startGame {
    margin-top: 5px;

    >p {
        margin-left: 10px;
    }
}

.startGame__body {
    display: flex;
    gap: 4px;
    margin-top: 10px;
    > p {
        display: flex;
        background-color: #272727;
        width: 20%;
        color: #EDEDFD;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
}

.btn {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    > button {
        font-size: 16px;
        width: 135px;
        height: 50px;
        border-radius: 12px;
        border: none;
        color: #EDEDFD;
        :first-child {
            background-color: #0FA44D;
        }
        :last-child {
            background-color: #D21C1C;
        }
    }
    > button:first-child {
        background-color: #0FA44D;
    }
    > button:last-child {
        background-color: #D21C1C;
    }
}

.btnAgree {
    width: 100%;
    border: none;
    border-radius: 12px;
    padding: 12px 0;
    background: #4C4AEF;
    color: #fff;
}