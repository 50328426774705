.Friends {
    margin-bottom: 100px;
}

.friends_body {
    margin: 20px 20px 0;
    padding: 16px;
    background: linear-gradient(180.00deg, rgb(254, 198, 165) 0%, rgb(255, 231, 217) 93.794%);
    border-radius: 16px 16px 0px 0px;
}

.friends_info {
    margin: 0 20px;
    padding: 16px;
    background: rgb(25, 27, 30);
    border-radius: 0px 0px 16px 16px;

    >p {
        color: rgb(180, 180, 180);
        font-size: 14px;
        font-weight: 400;
        font-family: 'PoppinsRegular';
    }
}

.friends_list {
    margin: 10px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div > div {
        margin-top: 20px;
        padding: 16px;
        border-radius: 16px;
        background: rgb(25, 27, 30);
        display: flex;
        align-items: start;
        gap: 12px;

        >p {
            font-size: 17px;
            font-weight: 500;
        }

        >div {
            width: 100%;

            >div {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                >p:first-child {
                    color: rgb(180, 180, 180);
                    font-size: 11px;
                }

                >p:last-child {
                    color: rgb(216, 216, 216);
                    font-size: 12px;
                }
            }
        }
    }
}